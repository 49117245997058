//
// Badge
// --------------------------------------------------

// Fix secondary and light badges colors
[data-bs-theme="dark"] {
  .badge.bg-secondary,
  .badge.zz-bg-secondary,
  .badge.bg-light {
    --#{$prefix}badge-color: var(--zz-white);
  }
}

[data-bs-theme="light"] {
  .badge.bg-secondary,
  .badge.zz-bg-secondary,
  .badge.bg-light {
    --#{$prefix}badge-color: #{$gray-800};
  }
}
// Badge link

a.badge { // stylelint-disable-line selector-no-qualifying-type
  text-decoration: none;
}

// Fix badge alignment inside button

.btn .badge {
  top: 1px;
}
