a {
  color: var(--primary-base);
  --zz-heading-link-hover-color: var(--primary-base);

  &:not(.btn, .list-group-item-action) {
    &:hover {
      color: var(--primary-darker-300) !important;
    }
  }
}
