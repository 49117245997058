[data-bs-theme="dark"] {
  .box {
    color: #fff;
    background-color: #333;
    border-color: #444;
  }
}
.box {
  padding: 3rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}
