//
// user-variables.scss
// Use this to override Bootstrap and Silicon variables
// These are the default variables which can also be set via the CMS
// --------------------------------------------------

// add the css vars from the custom css here and map them to base variables in root
// which are rendered by the python code.

:root {
  --zz-heading-link-hover-color: var(--primary-base);
}

::selection {
  background: var(--primary-transparent-50);
}

[data-bs-theme="dark"] .navbar:not([data-bs-theme="light"]) {
  --zz-navbar-hover-color: var(--primary-base);
  --zz-navbar-active-color: var(--primary-base);
}

.navbar {
  --zz-navbar-hover-color: var(--primary-base);
  --zz-navbar-active-color: var(--primary-base);
}

.navbar-nav {
  --zz-nav-link-hover-color: var(--primary-base);
}

[data-bs-theme="light"] {
  .navbar {
    &[data-bs-theme="dark"] .navbar-toggler-icon {
      &,
      &::before,
      &::after {
        background-color: var(--#{$prefix}gray-800);
      }
    }
  }
  .navbar-toggler-icon {
    &,
    &::before,
    &::after {
      background-color: var(--#{$prefix}gray-dark);
    }
  }
}

.btn-primary {
  --#{$prefix}btn-hover-color: #fff;
  --#{$prefix}btn-active-color: #fff;
  --#{$prefix}btn-bg: var(--primary-base);
  --#{$prefix}btn-border-color: var(--primary-base);
  --#{$prefix}btn-hover-bg: var(--primary-darker-200);
  --#{$prefix}btn-active-bg: var(--primary-darker-400);
  --#{$prefix}btn-hover-border-color: var(--primary-darker-200);
  --#{$prefix}btn-active-border-color: var(--primary-darker-400);
  --#{$prefix}btn-disabled-color: var(--lighter-primary-400);
  --#{$prefix}btn-disabled-bg: var(--lighter-primary-700);
}

.btn-secondary {
  --#{$prefix}btn-hover-color: var(--zz-black);
  --#{$prefix}btn-active-color: var(--zz-black);
  --#{$prefix}btn-bg: var(--secondary-lighter-400);
  --#{$prefix}btn-border-color: var(--secondary-lighter-400);
  --#{$prefix}btn-hover-bg: var(--secondary-darker-200);
  --#{$prefix}btn-active-bg: var(--secondary-darker-400);
  --#{$prefix}btn-hover-border-color: var(--secondary-darker-200);
  --#{$prefix}btn-active-border-color: var(--secondary-darker-400);
  --#{$prefix}btn-disabled-color: var(--secondary-lighter-400);
  --#{$prefix}btn-disabled-bg: var(--secondary-lighter-700);
}

[data-bs-theme="dark"] .btn-secondary:not(.bg-white, .bg-light) {
  --#{$prefix}btn-hover-color: #fff;
  --#{$prefix}btn-active-color: #fff;
  --#{$prefix}btn-bg: var(--secondary-transparent-20);
  --#{$prefix}btn-border-color: var(--secondary-transparent-20);
  --#{$prefix}btn-hover-bg: var(--secondary-transparent-50);
  --#{$prefix}btn-hover-border-color: var(--secondary-transparent-50);
  --#{$prefix}btn-active-bg: var(--secondary-transparent-50);
  --#{$prefix}btn-active-border-color: var(--secondary-transparent-50);
}

.breadcrumb-item.active {
  color: var(--primary-base);
}

.zz-bg-primary {
  ::selection {
    background: var(--primary-darker-transparent);
  }
  [data-bs-theme="light"] {
    .breadcrumb-item.active {
      color: var(--#{$prefix}black);
    }
    .breadcrumb-item > a {
      color: var(--primary-darker-600);
    }
    .breadcrumb {
      --#{$prefix}breadcrumb-divider-color: var(--primary-darker-600);
    }
  }
  .breadcrumb-item.active {
    color: var(--#{$prefix}white);
  }
  .breadcrumb-item > a {
    color: var(--primary-lighter-900);
    &:hover {
      color: var(--zz-white);
    }
  }
  .breadcrumb {
    --#{$prefix}breadcrumb-divider-color: var(--primary-lighter-900);
  }
}

.breadcrumb {
  --zz-breadcrumb-divider: "\ea50";
}

.text-primary {
  color: var(--primary-base) !important;
}
