:root {
  [class^="container"] {
    @media screen and (max-width: map-get($grid-breakpoints, "sm")) {
      --zz-gutter-x: 3rem; /* Change to desired horizontal padding */
    }
  }
}

.mode-switch {
  .form-check-input {
    cursor: pointer;
  }
}

[data-bs-theme="dark"] {
  .bg-light {
    &.hero {
      background-color: #fff !important;
    }
    &.navbar {
      background-color: #fff !important;
      .form-switch.mode-switch .form-check-input:checked ~ .form-check-label:first-of-type {
        color: $gray-700 !important;
      }
      .form-switch.mode-switch .form-check-input:checked ~ .form-check-label:last-of-type {
        color: $gray-900 !important;
      }
      .btn-close {
        filter: invert(0) grayscale(100%) brightness(200%);
      }
    }
  }
}

.navbar {
  &.bg-auto {
    &[data-bs-theme="light"] {
      .form-switch.mode-switch .form-check-input:checked ~ .form-check-label:first-of-type {
        color: $gray-700 !important;
      }
      .form-switch.mode-switch .form-check-input:checked ~ .form-check-label:last-of-type {
        color: $gray-900 !important;
      }
    }
  }
}

.w-xs-100 {
  @media screen and (min-width: map-get($grid-breakpoints, "xs")) {
    width: 100%;
  }
}


:root[data-bs-theme="auto"] {
  background-color: $light;
}

@media (prefers-color-scheme: dark) {
  :root[data-bs-theme="auto"] {
    background-color: $dark;
  }
}
