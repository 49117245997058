.table {
  td {
    white-space: nowrap;
  }

  caption {
    margin-left: .5rem;
  }
}

.table-responsive {
  @media screen and (max-width: map-get($grid-breakpoints, "sm")) {
    --zz-gutter-x: -3rem;
  }
}
