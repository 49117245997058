section {
  .carousel {
    .swiper-pagination {
      --zz-carousel-pagination-progressbar-fill-bg: var(--primary-base);
      --zz-carousel-pagination-bullet-active-bg: var(--primary-base);
    }
    .btn-prev {
      left: -2.5rem;
      --zz-carousel-nav-btn-hover-bg: var(--primary-base);
    }
    .btn-next {
      --zz-carousel-nav-btn-hover-bg: var(--primary-base);
      right: -2.5rem;
    }
  }
}
