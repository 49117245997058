.report {
  .section-sm {
    padding-top: $section-padding-sm;
    padding-bottom: $section-padding-sm;
  }
  .section-md {
    padding-top: $section-padding-md;
    padding-bottom: $section-padding-md;
  }
  .section-lg {
    padding-top: $section-padding-lg;
    padding-bottom: $section-padding-lg;
  }
}
