.theme-mode-checkbox {
  display: none;
}

.theme-toggle {
  cursor: pointer;
  .bx-sun {
    display: none;
  }
}

.theme-mode-checkbox:checked ~ .bx-sun {
  display: inline;
}

.theme-mode-checkbox:checked ~ .bx-moon {
  display: none;
}

.theme-mode-checkbox:not(:checked) ~ .bx-moon {
  display: inline;
}

[data-bs-theme="light"] {
  [data-hero-mode="dark"]{
    .btn-link{
      color: var(--zz-btn-color);
    }
  }
}

[data-bs-theme="dark"] {
  [data-bs-theme="light"]{
    .btn-link{
      color: var(--zz-dark);
    }
  }
}
