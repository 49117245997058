.zz-bg-secondary {
  background-color: var(--secondary-transparent-10);
}

[data-bs-theme="light"] {
  .zz-bg-white,
  .zz-bg-black {
    color: $black;
    background-color: $white;
    .icon svg {
      fill: $black;
    }
    &:hover {
      color: $gray-800;
      .icon svg {
        fill: $gray-800;
      }
      background-color: $gray-200;
    }
  }
  .zz-bg-secondary {
    background-color: var(--secondary-transparent-35);
  }
  [class*="zz-bg-custom-"],
  [class*="is-custom"],
  .zz-bg-primary {
    .btn-primary {
      color: var(--zz-dark);
      background-color: rgba(255, 255, 255, .8);
      border-color: transparent;
      &:hover {
        background-color: rgba(255, 255, 255, .9);
        border-color: transparent;
      }
    }
    .btn-secondary {
      background-color: rgba(255, 255, 255, .3);
      border-color: transparent;
      &:hover {
        background-color: rgba(255, 255, 255, .5);
        border-color: transparent;
      }
    }
  }
}

[data-bs-theme="dark"] {
  .zz-bg-white,
  .zz-bg-black {
    color: $white;
    background-color: $black;
    .icon svg {
      fill: $white;
    }
    &:hover {
      color: $gray-200;
      .icon svg {
        fill: $gray-200;
      }
      background-color: $gray-800;
    }
  }
  [class*="zz-bg-custom-"],
  [class*="is-custom"],
  .zz-bg-primary, {
    .btn-primary {
      color: var(--zz-white);
      background-color: rgba(0, 0, 0, .4);
      border-color: transparent;
      &:hover {
        background-color: rgba(0, 0, 0, .5);
        border-color: transparent;
      }
    }
    .btn-secondary {
      background-color: rgba(0, 0, 0, .1);
      border-color: transparent;
      &:hover {
        background-color: rgba(0, 0, 0, .2);
        border-color: transparent;
      }
    }
  }
}


// Form switch coloring
.is-primary {
  .form-switch.mode-switch .form-check-input,
  [data-bs-theme="dark"] .form-switch:not([data-bs-theme="light"]).mode-switch .form-check-input {
    background-color: var(--primary-darker-300);
  }
}

[class*="is-custom"] {
  .form-switch.mode-switch .form-check-input,
  [data-bs-theme="dark"] .form-switch:not([data-bs-theme="light"]).mode-switch .form-check-input {
    background-color: rgba(0, 0, 0, .2) !important;
  }
}

.zz-bg-light {
  background-color: $white;
}

.zz-bg-dark {
  background-color: var(--zz-body-bg);
}
