.container {
  &.is-hero {
    padding-top: 5rem;
    @at-root {
      .has-breadcrumbs & {
        padding-top: 8.5rem;
      }
    }
  }
}
