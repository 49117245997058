.header-icon {
  width: 200px;
  height: auto;
  @media screen and (max-width: map-get($grid-breakpoints, "lg")) {
    width: 150px;
  }
  @media screen and (max-width: map-get($grid-breakpoints, "md")) {
    width: 100px;
  }
}
