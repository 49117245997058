.hero {
  &.has-background {
    position: relative;
    overflow: hidden;
    .darker::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      content: "";
      background: linear-gradient(to top, rgba(0, 0, 0, .7) 0%, rgba(0, 0, 0, 0) 100%);
    }
  }
  &.is-transparent {
    .hero-background {
      opacity: .3;
    }
  }
  .hero-background {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  .hero-body {
    position: relative;
    z-index: 2;
    text-wrap: balance;
    &.is-small {
      .content {
        height: 32em;
        @media screen and (max-width: map-get($grid-breakpoints, "md")) {
          height: auto;
          .align {
            padding: 2em 0;
            // margin-top: 2em;
          }
        }
      }
    }
    &.is-medium {
      .content {
        height: 36em;
        @media screen and (max-width: map-get($grid-breakpoints, "md")) {
          height: auto;
          .align {
            padding: 3.5em 0;
          }
        }
      }
    }
    &.is-large {
      .content {
        height: 48em;
        @media screen and (max-width: map-get($grid-breakpoints, "md")) {
          height: auto;
          .align {
            padding: 4em 0;
          }
        }
      }
    }
    h1 {
      line-height: 1.15;
    }
    // &.is-half {
    //   .content {
    //     height: 50%;
    //     @media screen and (max-width: map-get($grid-breakpoints, "md")) {
    //       height: auto;
    //     }
    //   }
    // }
    // &.is-three-quarters {
    //   .content {
    //     height: 75%;
    //     @media screen and (max-width: map-get($grid-breakpoints, "md")) {
    //       height: auto;
    //     }
    //   }
    // }
    // &.is-full {
    //   .content {
    //     height: 100%;
    //     @media screen and (max-width: map-get($grid-breakpoints, "md")) {
    //       height: auto;
    //     }
    //   }
    // }
  }
  .is-hero-image {
    picture {
      display: flex;
      justify-content: center;
    }
    &.is-small {
      picture {
        width: 16em;
        padding: 3em;
        @media screen and (max-width: map-get($grid-breakpoints, "lg")) {
          width: 14em;
          padding: 3em;
          margin-bottom: 2.2em;
        }
        @media screen and (max-width: map-get($grid-breakpoints, "md")) {
          width: 13em;
        }
        @media screen and (max-width: map-get($grid-breakpoints, "sm")) {
          padding: 2em;
        }
      }
    }
    &.is-medium {
      picture {
        width: 23em;
        padding: 4em;
        @media screen and (max-width: map-get($grid-breakpoints, "lg")) {
          width: 20em;
          padding: 3em;
          margin-bottom: 2.2em;
        }
        @media screen and (max-width: map-get($grid-breakpoints, "md")) {
          width: 16em;
          padding: 2.5em;
        }
        @media screen and (max-width: map-get($grid-breakpoints, "sm")) {
          width: 15em;
          padding: 2em;
        }
      }
    }
    &.is-large {
      picture {
        width: 27em;
        padding: 3em;
        @media screen and (max-width: map-get($grid-breakpoints, "lg")) {
          width: 24em;
          padding: 2.5em;
          margin-bottom: 2.2em;
        }
        @media screen and (max-width: map-get($grid-breakpoints, "sm")) {
          padding: 2em;
        }
      }
    }
    &.is-half {
      picture {
        display: flex;
        justify-content: center;
        width: 50%;
      }
    }
    &.is-three-quarter {
      picture {
        display: flex;
        justify-content: center;
        width: 75%;
      }
    }
    &.is-full {
      picture,
      img
      {
        height: 100%;
      }
    }
  }
}

[data-bs-theme="dark"] {
  .hero {
    &.has-background {
      .hero-background {
        &.is-dark {
          display: block;
        }
        &.is-light {
          display: none;
        }
      }
      &.bg-light {
        .hero-background {
          &.is-light {
            display: block;
          }
        }
      }
    }
  }
}

[data-bs-theme="light"] {
  .hero {
    &.has-background {
      .hero-background {
        &.is-dark {
          display: none;
        }
        &.is-light {
          display: block;
        }
      }
      &.bg-dark {
        .hero-background {
          &.is-dark {
            display: block;
          }
        }
      }
    }
  }
}

.has-hero {
  .header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1030;
    width: 100%;
  }
  .breadcrumbs {
    position: absolute;
    top: 5rem;
    z-index: 1029;
    width: 100%;
  }
}
