.linkboxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.linkbox {
  box-sizing: border-box;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  padding: 1rem;
  margin: .5rem;
  font-weight: 600;
  line-height: 1.1;
  text-decoration: none;
  text-wrap: balance;
  border-radius: .5rem;
  transition: all .2s ease-in-out;
  &:hover {
    --zz-heading-color: var(--zz-theme-hover-color);
    background-color: var(--zz-theme-hover-bgcolor);
    transform: scale(1.02);
  }
  @media screen and (max-width: map-get($grid-breakpoints, "sm")) {
    flex: 1 1 auto;
    flex-direction: row;
    width: 100%;
    max-width: none;
    margin: .2rem 0;
    .h5 {
      font-size: 1.05rem;
    }
  }
  @media screen and (min-width: map-get($grid-breakpoints, "sm")) {
    flex: 1 1 auto;
    width: 33%;
    max-width: none;
  }
  @media screen and (min-width: map-get($grid-breakpoints, "md")) {
    flex: 1 1 0;
  }
  .icon {
    display: flex;
    flex-grow: 1;
    align-content: flex-end;
    align-items: flex-end;
    justify-content: flex-end;
    width: 24px;
    height: 24px;
    margin-top: auto;
    @media screen and (min-width: map-get($grid-breakpoints, "sm")) {
      width: 100%;
    }
    svg {
      width: 24px;
      height: 24px;
      fill: var(--zz-fill-color);
    }
    path {
      fill: var(--zz-fill-color);
    }
  }
}
