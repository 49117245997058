.footer {
  .footer-content-block {
    .nav-item {
      margin-bottom: .25em;
      .nav-link {
        padding: .25rem 0;
        font-size: .875rem;
        font-weight: 500;
      }
    }
  }
  .bottom-links {
    .nav-item {
      .nav-link {
        font-size: .875rem;
      }
    }
  }
  .navbar-brand.has-payoff {
    .navbar-logo {
      &.is-tiny {
        max-height: .5em;
      }
      &.is-small {
        max-height: .75em;
      }
      &.is-medium {
        max-height: 1.25em;
      }
      &.is-large {
        max-height: 1.5em;
      }
      &.is-xl {
        max-height: 2em;
      }
    }
  }

  .navbar-logo {
    &.is-tiny {
      max-height: 1em;
    }
    &.is-small {
      max-height: 1.25em;
    }
    &.is-medium {
      max-height: 1.5em;
    }
    &.is-large {
      max-height: 2em;
    }
    &.is-xl {
      max-height: 2.5em;
    }
  }
}


[data-bs-theme="light"] {
  .footer {
    &[data-bs-theme="dark"] {
      .navbar-logo {
        &.is-dark {
          display: block;
        }
        &.is-light {
          display: none;
        }
      }
    }
    &[data-bs-theme="light"] {
      .navbar-logo {
        &.is-light {
          display: block;
        }
        &.is-dark {
          display: none;
        }
      }
    }
  }
}

[data-bs-theme="dark"] {
  .footer {
    .navbar-logo {
      &.is-light {
        display: none;
      }
      &.is-dark {
        display: block;
      }
    }
    &[data-bs-theme="dark"] {
      .navbar-logo {
        &.is-light {
          display: none;
        }
        &.is-dark {
          display: block;
        }
      }
    }
    &[data-bs-theme="light"] {
      .navbar-logo {
        &.is-light {
          display: block;
        }
        &.is-dark {
          display: none;
        }
      }
    }
  }
}
