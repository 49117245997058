.section {
  @at-root {
    .has-breadcrumbs & {
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
  &:first-of-type:not(&.hero) {
    position: relative;
    z-index: 99;
    margin-top: 2rem;
    &.is-floating {
      margin-top: -3rem;
      @media screen and (max-width: map-get($grid-breakpoints, "sm")) {
        margin-top: .5rem;
      }
    }
  }
}

.row {
  &.no-margin-x {
    --zz-gutter-x: 3rem;
  }
}
