[data-bs-theme="dark"] {
  .banner {
    &.bg-dark {
      background-color: var(--zz-secondary-bg) !important;
    }
  }
}

[data-bs-theme="light"] {
  .banner {
    &[data-bs-theme="dark"] {
      &.bg-dark {
        background-color: $gray-900 !important;
      }
    }
  }

  .bg-dark {
    .banner {
      &[data-bs-theme="dark"] {
        &.bg-dark {
          background-color: var(--zz-secondary-bg) !important;
        }
      }
    }
  }
}

.banner-col > .banner > .btn {
  margin-top: auto;
}

.section,
.banner {
  &.has-background {
    position: relative;
    overflow: hidden;
    .row {
      position: relative;
      z-index: 5;
    }
    .darker::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      content: "";
      background: linear-gradient(to top, rgba(0, 0, 0, .7) 0%, rgba(0, 0, 0, 0) 100%);
    }
  }
  &.is-transparent {
    .hero-background {
      opacity: .3;
    }
  }
  .banner-background {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
  .banner-image-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    &.h-center {
      picture {
        display: flex;
        justify-content: center;
      }
    }
    &.h-start {
      picture {
        display: flex;
        justify-content: start;
      }
    }
    &.h-end {
      picture {
        display: flex;
        justify-content: end;
      }
    }
  }
  .banner-image {
    &.full {
      width: 100%;
    }
    &.three-quarter {
      width: 75%;
    }
    &.half {
      width: 50%;
    }
    &.small {
      width: 100%;
      padding: 6em;
    }
    &.medium {
      width: 100%;
      padding: 4em;
    }
    &.large {
      width: 100%;
      padding: 2em;
    }
  }
}
