h1,
h2,
.lead {
  text-wrap: balance;
}

[data-bs-theme="dark"] {
  [data-bs-theme="light"] {
    p {
      color: #000;
    }
  }
}

[data-bs-theme="light"] {
  [data-bs-theme="dark"] {
    p {
      color: var(--zz-body-color);
    }
  }
}

.intro {
  p:last-child {
    margin-bottom: 0 !important;
  }
}

.icon-tile {
  display: flex;
  flex-direction: column;
  div:last-child {
    margin-top: auto;
  }
  > div {
    display: inline-block !important;
    width: auto;
  }
  p {
    text-wrap: balance;
  }
}

.text-body {
  ul {
    padding-left: 1.25rem;
  }
  ul > li {
    padding-left: .2rem;
    margin-bottom: .5rem;
    line-height: 1.3;
  }
}
