//
// Offcanvas
// --------------------------------------------------


// Header

.offcanvas-header {
  padding: calc(var(--#{$prefix}offcanvas-padding-y) * .75) var(--#{$prefix}offcanvas-padding-x);
  &.menu-actions {
    > *:nth-child(2) {
      margin-left: .5rem;
    }
  }
}


// Body

.offcanvas-body {
  display: block !important; // stylelint-disable-line declaration-no-important
}


// Dark color mode

@if $enable-dark-mode {
  @include color-mode(dark) {
    .offcanvas,
    .offcanvas-sm,
    .offcanvas-md,
    .offcanvas-lg,
    .offcanvas-xl,
    .offcanvas-xxl {
      &.bg-secondary {
        background-color: #14171f !important; // stylelint-disable-line declaration-no-important
      }
    }
  }
}
