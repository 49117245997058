.avatar {
  &-initials {
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    font-size: 3.5rem;
    font-weight: 700;
    color: var(--primary-base);
    text-transform: uppercase;
    background-color: var(--primary-transparent-50);
  }
}
